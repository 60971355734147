<template>
  <v-container class="general">
    <PageTitle :title="'Statistics'" />
    <v-card v-if="!isVuexLoading('listUsers')">
      <v-card-text>
        <div>
          Total users:
          <span class="font-weight-black">{{ totalUserCount }}</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <div class="mb-3 font-weight-black">SMS expenses</div>
        <div class="mb-3 d-flex align-center">
          <div class="d-flex">
            <date-picker
              v-model="startDate"
              class="mr-1"
              type="date"
              lang="en"
              :not-after="endDate"
              :first-day-of-week="1"
              format="[Start date:] MMMM DD, YYYY"
              placeholder="Start date"
              :editable="false"
              @input="getExpenses"
            ></date-picker>
            <date-picker
              v-model="endDate"
              class="ml-1"
              type="date"
              lang="en"
              :not-before="startDate"
              :first-day-of-week="1"
              format="[End date:] MMMM DD, YYYY"
              placeholder="End date"
              :editable="false"
              @input="getExpenses"
            ></date-picker>
          </div>
        </div>
        <template v-if="!isVuexLoading('getExpenses')">
          <div>
            Total sms's sent:
            <span class="font-weight-black">{{ expenses.total_sms }}</span>
          </div>
          <div v-if="expenses.total_cost.length">
            Total paid:
            <span v-for="(total, i) in expenses.total_cost" :key="`cost-${i}`">
              <span class="font-weight-black">{{ total.amount }}</span>
              {{ total.currency }}
              <span
                v-if="i !== 1 && expenses.total_cost.length > 1"
                class="px-1"
                >/</span
              >
            </span>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";

export default {
  data: () => ({
    totalUserCount: 0,
    startDate: "",
    endDate: "",
    expenses: {
      total_sms: 0,
      total_cost: [],
    },
  }),
  components: {
    DatePicker,
  },
  mounted() {
    this.listUsers();
    this.getExpenses();
  },
  methods: {
    transformDate(date) {
      if (!date) return;
      const tz = moment.tz.guess();
      return moment.tz(date, tz).format("Y-MM-DD");
    },
    listUsers() {
      const params = {
        per_page: 1,
      };

      this.startVuexLoading("listUsers");

      this.$api.users
        .list(params)
        .then((response) => {
          this.endVuexLoading("listUsers");

          this.totalUserCount = response.data.pagination.total;
        })
        .catch(() => {
          this.endVuexLoading("listUsers");
        });
    },
    getExpenses() {
      if (this.startDate && !this.endDate) {
        return;
      }

      if (this.endDate && !this.startDate) {
        return;
      }

      this.expenses = {
        total_sms: 0,
        total_cost: [],
      };

      const params = {
        start_date: this.transformDate(this.startDate),
        end_date: this.transformDate(this.endDate),
      };

      this.startVuexLoading("getExpenses");

      this.$api.expenses
        .sms(params)
        .then((response) => {
          this.endVuexLoading("getExpenses");

          this.expenses.total_sms = response.data.data.total_sms;

          for (const [key, value] of Object.entries(
            response.data.data.total_cost,
          )) {
            this.expenses.total_cost.push({
              currency: key,
              amount: value,
            });
          }
        })
        .catch(() => {
          this.endVuexLoading("getExpenses");
        });
    },
  },
};
</script>

<style scoped></style>
